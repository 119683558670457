import React from "react";
import headshot from "../../assets/media/headshot.jpeg";
import CanvasBackground from "../CanvasBackground/CanvasBackground.jsx";
import "./Header.css";

const Header = () => {
  return (
    <header id="home" className="header-background">
      <CanvasBackground />
      <div
        className="picture-container"
        style={{ position: "relative", zIndex: 1 }}
      >
        <img src={headshot} alt="profile" />
      </div>
      <p className="intro-text" style={{ position: "relative", zIndex: 1 }}>
        Hi, I'm Eddie! I'm a Software Engineer at Capital Group, specializing in
        Fixed Income SMA Trading Platforms. Leveraging my expertise in diverse
        programming languages, modern technologies, and software engineering
        best practices, I develop robust, high-performance, and innovative
        solutions. I also work closely with cross-functional teams and
        stakeholders to drive alignment, foster collaboration, and deliver
        impactful results.
      </p>
      <div className="social-icons" style={{ position: "relative", zIndex: 1 }}>
        <a
          href="https://github.com/eddayyy"
          target="_blank"
          rel="noopener noreferrer"
          className="github"
        >
          <iconify-icon
            icon="line-md:github"
            className="github-icon"
          ></iconify-icon>
        </a>
        <a
          href="https://www.linkedin.com/in/eduardong"
          target="_blank"
          rel="noopener noreferrer"
          className="linkedin"
        >
          <iconify-icon
            href="https://www.linkedin.com/in/eduardong"
            icon="line-md:linkedin"
            className="linkedin-icon"
          ></iconify-icon>
        </a>
      </div>
    </header>
  );
};

export default Header;
